.fileUpload {
  
  text-align: center;
  padding: 10px;
  width: 85%;
  border: 2px dashed #688a8f;
  border-radius:5px;  
  font-size: calc(10px + 2vmin);
  color: #bdbdbd;
  cursor: pointer;
  margin: 20px;
  background-color: #148dca18
}

.fileUpload p{
  margin-top: 5px;
  margin-bottom: 10px;
}

.filename{
  font-size: calc(3px + 2vmin);
  color: darkred;
  margin-left: 10px;
  vertical-align: text-top;
}

.parent{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.result{
  width: 85%;
  height: 230px;
  margin: 20px;
  resize: none;
  padding: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
}

