.policy {
  /* text-align: center; */
  font-family: Tahoma, Verdana, sans-serif;
  font-size: larger;
  background-color: rgb(255, 255, 255);
  
}

.policytext{


  padding: 10px 50px 10px 50px;
  background-color: rgba(198, 219, 238, 0.329);
  color: rgb(65, 65, 65);
}

.links{
  font-size: 16px !important;
  border-left-width: 2px !important;
  border-left-style: solid !important;
  padding-left: 7px !important;
  margin-bottom: -3px;
  cursor: pointer;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.footer{
  
  background-color: rgb(255, 255, 255);
  color: #707070;
  padding: 35px;
  border-width: 1px;
  border-style: dotted none none none;
  border-color: #919191; 
}

.parent{
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

