.instructions{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: rgba(198, 219, 238, 0.329); */
    color: #313131;
    font-weight: 400;
  }

  .intro{
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 30px;

    text-align: justify;
  }

  .howto ol li::before{
    counter-increment: numList;
    content: counter(numList);
    
    line-height: 27px;
    color: #0092d6;
    font-weight: 700;
    background: #fff;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 30px;
    height: 29px;
    width: 29px;
    display: inline-block;
    text-align: center;

  }

  .howto ol {
    list-style: none;
    counter-reset: numList;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    padding-right: 50px;
}

.howto ol li {
  padding-left: 25px !important;
  vertical-align: middle;
  position: relative;
  text-align: left;
  max-width: 100%;
}

.howto ol li span {
  display: inline-block;
  width: calc(100% - 50px);
  vertical-align: middle;
  padding-left: 15px;
}

@media ( max-width: 900px ) {
  .howto ol { flex-direction: column; }
}