.box{
  background-color: rgb(255, 255, 255); 
  color: #3a3a3a;

}

.box section{
  max-width: 90%;
  margin: auto;
}

.feature{
  padding: 0 50px;
}

.stack{
  display: flex;
  align-items: center;
  justify-content: left
}


.feature h3{
  padding-left: 10px;
  
}

.feature p{
  font-size: 18px;
  line-height: 26px;
}

.grid{
  display: grid;
  margin-bottom: 35px;
    display: grid;
    grid-row-gap: 25px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 910px){
  .grid {
      grid-template-columns: 1fr;
  }
}