.navbar {
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding: 0px 25px;

  border-width: 1px;
  border-style: none none dotted none;
  border-color: #7a7979; 
}

.navbar > h3 {
  color: #006699;
  margin-left: 10px;
}

.navbar > img {
  cursor: pointer;
}