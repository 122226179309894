.App {
  /* text-align: center; */
  font-family: Tahoma, Verdana, sans-serif;
  font-size: larger;
  background-color: rgba(198, 219, 238, 0.329);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

