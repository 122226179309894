

.footer{
  
  background-color: rgb(255, 255, 255);
  color: #707070;
  padding: 35px;
  border-width: 1px;
  border-style: dotted none none none;
  border-color: #919191; 
}

.logo{
  max-width: fit-content; /* prevent div from using up entire width od page */
  padding-bottom: 20px;
  color: #006699;
  font-weight: bold;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #707070;

}

.count{

  font-family: 'Courier New', Courier, monospace;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
  color: #006699;
  font-weight: 600;
}

.counttext{
  
  color: #707070;
  font-size: 14px;
  color: #3a3a3a;

}

.parent{
  justify-content: center;
}

.copyright{
  font-size: 16px;
  margin-top: 15px;
}

.links{
  font-size: 16px !important;
  border-left-width: 2px !important;
  border-left-style: solid !important;
  padding-left: 7px !important;
  cursor: pointer;
}
